<template>
  <v-container>
    <v-alert type="info" v-if="contestants.length === 0">
      Sorry! There is no active giveaway at the moment. Please check again
      later.
    </v-alert>
    <v-data-table
      v-else
      hide-default-footer
      sort-by="place"
      :headers="headers"
      :items="contestants"
      :items-per-page="15"
      :item-class="test"
      class="elevation-1"
      mobile-breakpoint="0"
    ></v-data-table>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import "animate.css";

export default {
  name: "Giveaway",
  computed: {
    ...mapGetters("giveaway", ["contestants", "picked"]),
  },

  data: () => ({
    headers: [
      { text: "Place", value: "place", sortable: false },
      { text: "Name", value: "name", sortable: false },
    ],
  }),

  methods: {
      test(item) {
          let style = item.id === this.picked.id ? "white--text error animate__bounceIn" : "";

          style = item.place === 1 ? "white--text info animate__bounceIn" : style;

          return style;
      }
  },
};
</script>